import { styled } from '@mui/material';
import { Theme } from '@mui/system';

export const ListContainer = styled('ul')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content'
  }
}));
