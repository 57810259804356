import React from 'react';

export const IconFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.46"
      height="13.815"
      style={{
        marginRight: '12px'
      }}
      viewBox="0 0 19.46 13.815"
    >
      <g id="Group_738" data-name="Group 738" transform="translate(-11618.089 -936.188)">
        <g id="Group_108" data-name="Group 108">
          <path
            id="Path_282"
            data-name="Path 282"
            d="M11630.984,936.188h-12.554a.342.342,0,0,0-.29.522l5.142,7.44v5.538a.315.315,0,0,0,.5.257l2.089-1.478a.625.625,0,0,0,.265-.511V944.15l5.141-7.44A.341.341,0,0,0,11630.984,936.188Z"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_283"
            data-name="Path 283"
            d="M11636.945,940.514h-4.783a.6.6,0,1,1,0-1.209h4.783a.6.6,0,0,1,0,1.209Z"
            fill="#fff"
          />
          <path
            id="Path_284"
            data-name="Path 284"
            d="M11636.945,943.71h-7.059a.605.605,0,0,1,0-1.21h7.059a.605.605,0,0,1,0,1.21Z"
            fill="#fff"
          />
          <path
            id="Path_285"
            data-name="Path 285"
            d="M11636.945,946.906h-7.059a.605.605,0,0,1,0-1.21h7.059a.605.605,0,0,1,0,1.21Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
