import { SvgIcon } from '@mui/material';
import React from 'react';

export const IconDiscoverProperty = () => {
  return (
    <SvgIcon
      sx={{
        height: '90%',
        maxWidth: '488px',
        width: '100%'
      }}
      viewBox="0 0 487.989 355.258"
    >
      <g id="Group_708" data-name="Group 708" transform="translate(-881.268 -2046.271)">
        <g id="Group_337" data-name="Group 337" transform="translate(0 -209.775)">
          <g id="Group_333" data-name="Group 333">
            <g id="Group_332" data-name="Group 332">
              <path
                id="Path_628"
                data-name="Path 628"
                d="M1130.913,2285.64a1.729,1.729,0,0,1-.312-.019,1.677,1.677,0,0,1-1.4-1.913h0c.011-.077,1.115-7.837-2.288-25.671a1.677,1.677,0,0,1,3.3-.628c3.535,18.528,2.359,26.486,2.307,26.815A1.677,1.677,0,0,1,1130.913,2285.64Z"
                fill="#00abec"
              />
              <path
                id="Path_629"
                data-name="Path 629"
                d="M1191.23,2289.9a1.677,1.677,0,0,1-1.446-2.6h0c.042-.064,4.3-6.644,8.7-24.258a1.677,1.677,0,1,1,3.254.813c-4.571,18.3-8.979,25.028-9.164,25.306A1.679,1.679,0,0,1,1191.23,2289.9Z"
                fill="#00abec"
              />
              <path
                id="Path_630"
                data-name="Path 630"
                d="M1234.181,2326.871a1.677,1.677,0,0,1-.642-3.245c.141-.055,7.434-2.947,21.213-14.633a1.677,1.677,0,1,1,2.169,2.558c-14.384,12.2-21.888,15.1-22.2,15.213A1.661,1.661,0,0,1,1234.181,2326.871Z"
                fill="#00abec"
              />
            </g>
          </g>
          <path
            id="Path_631"
            data-name="Path 631"
            d="M1130.926,2503.166c-26.2,0-52.452-7.153-69.912-20.516-31.086-23.791-51.363-74.6-27.714-121.9,23.757-47.513,76.559-55.223,77.92-55.41,32.768-4.73,62.018,2.052,84.591,19.61,25.183,19.59,36.435,48.15,38.568,68.345a105.2,105.2,0,0,1-11.505,59.675c-10.423,19.681-26.424,34.229-46.274,42.071C1162.717,2500.529,1146.828,2503.166,1130.926,2503.166Zm.121-195.286a134.819,134.819,0,0,0-19.262,1.422l-.027,0c-.517.067-51.9,7.285-74.88,53.235a91.99,91.99,0,0,0-6.094,67.732c5.725,19.764,17.629,37.7,32.66,49.2,27.052,20.7,75.3,25.785,110.88,11.729,18.951-7.488,33.626-21.687,43.6-40.514,9.206-17.382,14.553-37.349,12.481-56.971-2.046-19.377-12.853-46.788-37.046-65.608C1176.168,2314.74,1154.893,2307.88,1131.047,2307.88Zm-19.547-.558h0Z"
            fill="#151f35"
          />
          <path
            id="Path_632"
            data-name="Path 632"
            d="M1126.458,2488.849a104.436,104.436,0,0,1-17.893-1.505,83.584,83.584,0,0,1-69-84.686c1.249-47.348,41-70.182,41.4-70.408l.026-.014c51.56-27.939,93.029-2.234,108.465,10.115,18.152,14.522,42.758,59.049,20.349,100.974-10.035,18.777-26.583,32.534-47.852,39.786A111.481,111.481,0,0,1,1126.458,2488.849Zm-42.409-152.216c-.812.465-39.3,21.367-40.483,66.131a79.583,79.583,0,0,0,65.687,80.639c6.752,1.18,16.716.752,22.8.753,42.412,0,64.877-25.215,74.232-42.717,21.3-39.853-2.073-82.167-19.321-95.965C1172.149,2333.627,1133.5,2309.853,1084.049,2336.633Z"
            fill="#151f35"
          />
          <path
            id="Path_633"
            data-name="Path 633"
            d="M1067.912,2377.73a2,2,0,0,1-1.719-3.019c20.065-33.905,43.588-32.932,44.584-32.867a2,2,0,0,1,1.879,2.114c-.065,1.1-1.959,3.3-3.066,3.244-.086,0-5.589-.224-13.3,3.416-7.179,3.388-16.837,9.544-26.654,26.13A2,2,0,0,1,1067.912,2377.73Z"
            fill="#151f35"
          />
          <path
            id="Path_634"
            data-name="Path 634"
            d="M1050.966,2510.637a2,2,0,0,1-1.587-3.216l16.911-22.094a2,2,0,0,1,3.177,2.431l-16.911,22.095A2,2,0,0,1,1050.966,2510.637Z"
            fill="#151f35"
          />
          <path
            id="Path_635"
            data-name="Path 635"
            d="M1058.534,2517.623a2,2,0,0,1-1.607-3.189l17.5-23.685a2,2,0,0,1,3.217,2.377l-17.5,23.685A2,2,0,0,1,1058.534,2517.623Z"
            fill="#151f35"
          />
          <path
            id="Path_636"
            data-name="Path 636"
            d="M991.755,2611.3a6.018,6.018,0,0,1-1.25-.135c-3.152-.664-13.468-8.118-16-11.217-2.985-3.648-2.876-7.847.3-11.522,1.462-1.693,4-4.839,7.523-9.192,2.422-3,5.307-6.565,8.626-10.634,4.295-5.265,15.708-19.556,25.777-32.164,9.418-11.792,17.55-21.975,19.366-24.166l.375-.454c3.667-4.431,6.087-7.36,10.521-6.117,3.463.969,7.886,4.638,8.705,5.336.53.364,3.285,2.266,6.948,4.983,5.332,3.957,2.577,9.9-.31,13.806-.257.348-.506.684-.739,1.007-1.527,2.109-6.4,8.361-11.559,14.98-4.425,5.678-9,11.55-12.288,15.852-7.159,9.371-31.5,37.13-36.258,42.433C997.369,2608.686,994.858,2611.3,991.755,2611.3Zm53.364-101.871c-1.342,0-2.5,1.225-5.572,4.937l-.377.456c-1.792,2.163-10.306,12.824-19.32,24.11-10.076,12.616-21.495,26.916-25.8,32.2-3.315,4.064-6.2,7.628-8.614,10.62-3.542,4.383-6.1,7.55-7.607,9.292-1.881,2.179-1.956,4.264-.229,6.374,2.235,2.731,11.869,9.444,13.734,9.837,1.3.27,2.779-.924,7.178-5.827,4.725-5.265,26.857-32.266,33.98-41.59,3.3-4.318,7.88-10.2,12.312-15.883,5.137-6.591,9.989-12.816,11.474-14.867.241-.332.5-.679.762-1.038,3.385-4.583,5-7.5,3.219-8.815-3.995-2.964-6.88-4.935-6.909-4.954l-.178-.136c-1.227-1.055-4.922-3.936-7.261-4.591A2.961,2.961,0,0,0,1045.119,2509.433Z"
            fill="#151f35"
          />
          <g id="Group_334" data-name="Group 334">
            <path
              id="Path_637"
              data-name="Path 637"
              d="M1300.779,2364.024s-31.369,28.784-39.572,35.816-10.313,9.375-9.376,9.844,18.517-.7,19.455.469-.7,24.376-1.407,36.565-.426,17.415.746,17.884,22,1.063,25.272.867c2.371-.142,27.593.278,29.5.278,2.9,0,25.814.191,27.455-.278s.937-25.314.937-39.143-.469-15.47-.469-15.47,11.72-1.172,14.3-.938,1.875-1.875-.469-3.516-29.064-25.548-37.971-34.455a180.481,180.481,0,0,0-18.517-15.939Z"
              fill="#d9dfef"
            />
            <path
              id="Path_638"
              data-name="Path 638"
              d="M1319.931,2468.728c-.033-.205-.783-5.122.374-14.825.952-7.992.005-25.04-.3-29.98-4.024-.087-15.709-.333-19.313-.366-1.386,3.672-2.208,24.843-2.208,44.135h-3c0-.114.005-11.566.383-22.87.223-6.657.53-11.969.913-15.788.653-6.5,1.414-8.481,3.258-8.481,3.035,0,20.659.384,21.408.4l1.369.029.1,1.366c.062.9,1.523,22.271.374,31.909-1.1,9.229-.4,13.955-.389,14Z"
              fill="#fff"
            />
          </g>
          <g id="Group_335" data-name="Group 335">
            <path
              id="Path_639"
              data-name="Path 639"
              d="M930.973,2364.114s-31.72,29.106-40.016,36.216-10.428,9.481-9.48,9.955,18.724-.711,19.672.474-.711,24.65-1.422,36.975-.431,17.61.754,18.084,22.242,1.076,25.555.877c2.4-.144,27.9.281,29.832.281,2.932,0,26.1.193,27.763-.281s.948-25.6.948-39.582-.474-15.643-.474-15.643,11.85-1.185,14.458-.948,1.9-1.9-.474-3.555-29.39-25.835-38.4-34.841a182.533,182.533,0,0,0-18.724-16.118Z"
              fill="#d9dfef"
            />
            <path
              id="Path_640"
              data-name="Path 640"
              d="M950.356,2469.988c-.033-.206-.791-5.177.378-14.987.964-8.091,0-25.358-.3-30.334-4.06-.087-15.922-.338-19.557-.371-1.405,3.686-2.237,25.115-2.237,44.647h-3c0-.116,0-11.7.387-23.126.225-6.732.536-12.1.923-15.964.659-6.56,1.424-8.561,3.278-8.561,3.068,0,20.889.389,21.647.406l1.369.03.095,1.365c.063.915,1.54,22.519.378,32.263-1.112,9.336-.4,14.117-.393,14.164Z"
              fill="#fff"
            />
          </g>
          <g id="Group_336" data-name="Group 336">
            <path
              id="Path_641"
              data-name="Path 641"
              d="M1119.441,2360.781s-31.725,29.11-40.022,36.222-10.43,9.482-9.482,9.956,18.727-.711,19.675.474-.711,24.653-1.422,36.98-.431,17.613.754,18.087,22.246,1.076,25.559.877c2.4-.144,27.907.281,29.837.281,2.932,0,26.107.193,27.766-.281s.948-25.6.948-39.588-.474-15.645-.474-15.645,11.853-1.185,14.46-.948,1.9-1.9-.474-3.556-29.394-25.839-38.4-34.847a182.434,182.434,0,0,0-18.727-16.119Z"
              fill="#d9dfef"
            />
            <path
              id="Path_642"
              data-name="Path 642"
              d="M1138.826,2466.67c-.032-.207-.791-5.177.379-14.989.964-8.093,0-25.363-.306-30.338-4.06-.087-15.924-.339-19.56-.372-1.4,3.687-2.237,25.119-2.237,44.654h-3c0-.115.005-11.7.387-23.128.225-6.733.536-12.1.923-15.967.659-6.562,1.425-8.562,3.278-8.562,3.069,0,20.893.389,21.65.406l1.37.029.094,1.366c.064.915,1.541,22.522.379,32.268-1.113,9.337-.4,14.118-.393,14.166Z"
              fill="#fff"
            />
          </g>
          <path
            id="Path_643"
            data-name="Path 643"
            d="M1159.062,2389.844q-.444,0-.916-.021a1.5,1.5,0,1,1,.14-3c3.709.173,6.361-.914,7.274-2.98a2.86,2.86,0,0,0-.377-3.123,11.11,11.11,0,0,0-3.057-1.738c-2.673-1.2-6.333-2.844-5.376-7.247,1.069-4.916,7.67-5.3,11.4-4.757a1.5,1.5,0,0,1-.432,2.969c-1.89-.271-7.421-.41-8.037,2.425-.38,1.747.536,2.465,3.673,3.873a13.031,13.031,0,0,1,3.949,2.354,5.828,5.828,0,0,1,1,6.456C1167.653,2386.533,1165.473,2389.844,1159.062,2389.844Z"
            fill="#00a6dd"
          />
          <path
            id="Path_644"
            data-name="Path 644"
            d="M1161.173,2393.416a1.5,1.5,0,0,1-1.5-1.5c0-6.962,1.83-26.361,1.909-27.184a1.5,1.5,0,0,1,2.986.284c-.019.2-1.9,20.079-1.9,26.9A1.5,1.5,0,0,1,1161.173,2393.416Z"
            fill="#00a6dd"
          />
          <path
            id="Path_645"
            data-name="Path 645"
            d="M1137.934,2401.021a1.5,1.5,0,0,1-1.415-2l4.07-11.6c-2.723-10.04-2.634-16.575,4.831-26.015a20.518,20.518,0,0,1,18.863-7.749c16.821.614,23.186,12.6,23.53,23.631.255,8.161-4.182,18.579-14.528,22.168-9.616,3.336-20.318-1.26-22.977-2.533l-1.72.625c-2.322.844-9.871,3.293-10.191,3.4A1.477,1.477,0,0,1,1137.934,2401.021Zm12.465-7.225a1.507,1.507,0,0,1,.7.172c.114.061,12.123,5.308,21.723,1.979,10.262-3.561,12.672-14.142,12.513-19.24-.147-4.7-2.594-19.423-21.252-20.052a1.581,1.581,0,0,1-.217-.023,17.49,17.49,0,0,0-16.091,6.637c-6.816,8.621-6.795,14.313-4.167,23.811a1.5,1.5,0,0,1-.031.9l-3.22,9.178c2.468-.81,5.835-1.924,7.208-2.423l2.323-.844A1.487,1.487,0,0,1,1150.4,2393.8Z"
            fill="#00a6dd"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
