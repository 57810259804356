import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { CraftGql_CarouselCard_Card_BlockType } from '../../graphql-types';
import {
  FinancialReviewLogo,
  HeraldSunLogo,
  OnlineMarketplaceLogo,
  SmartCompanyLogo
} from '../assets/icons/logo';
import { WidthAdjustBox } from './primitives/width-adjust-box';

interface ClientReviewBoxProps {
  isMobile?: boolean;
  content?: CraftGql_CarouselCard_Card_BlockType[]
}

const clientReviews = [
  {
    comments: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed',
    logo: <FinancialReviewLogo />
  },
  {
    comments: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed',
    logo: <SmartCompanyLogo />
  },
  {
    comments: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed',
    logo: <HeraldSunLogo />
  },
  {
    comments: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed',
    logo: <OnlineMarketplaceLogo />
  }
];

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  900: { items: 3 },
  1025: { items: 4 }
};

export const ClientReviewBox = (props: ClientReviewBoxProps) => {
  const { isMobile } = props;

  const slides = props.content?.map((review, index) => (
    <div className="item" style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        key={index}
        textAlign="center"
        width="295px"
        padding="28px"
        marginLeft={{ xs: '0', md: '30px', lg: '30px' }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" height="70px">
          <img src={review.carouselImage[0].url} />
        </Box>
        <Typography component="div" fontSize="14px" lineHeight="22px">
          "{review.carouselText}"
        </Typography>
      </Box>
    </div>
  ));

  return (
    <WidthAdjustBox>
      <Box
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'center', lg: 'center' }}
        marginTop={{ md: '48px' }}
        sx={{ overflowX: 'auto' }}
      >
        <AliceCarousel
          disableButtonsControls
          mouseTracking
          items={slides}
          responsive={responsive}
          // renderDotsItem={{}}
          controlsStrategy="alternate"
          disableDotsControls={!isMobile}
        />
        {/* {clientReviews.map((review, index) => (
          <Box
            key={index}
            textAlign="center"
            width="295px"
            padding="28px"
            marginLeft={{ xs: '0', md: '30px', lg: '30px' }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" height="70px">
              {review.logo}
            </Box>
            <Typography component="div" fontSize="14px" lineHeight="22px">
              "{review.comments}"
            </Typography>
          </Box>
        ))} */}
      </Box>
    </WidthAdjustBox>
  );
};
