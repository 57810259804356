import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_DownloadApp_BlockType } from '../../graphql-types';
import { ImageAppStore, ImageGooglePlayStore, ImageMobile } from '../assets/images';
import { LIGHT_VIOLET_COLOR } from '../colors';
import { linkStyle } from '../constants';

export const SecretPropertyMarket = ({
  content
}: {
  content?: CraftGql_Body_DownloadApp_BlockType;
}) => {
  const theme = useTheme();

  return (
    <Grid
      // alignItems="center"
      borderRadius="26px 26px 26px 0"
      container
      maxWidth={{ xs: '100%' }}
      mx="auto"
      sx={{
        background: LIGHT_VIOLET_COLOR,
        padding: '12px',
        paddingBottom: 0
      }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        marginTop="40px"
        textAlign="center"
        order={{ xs: 2, md: 2, lg: 1 }}
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            [theme.breakpoints.down('lg')]: {
              position: 'static'
            }
          }}
        >
          <img src={ImageMobile} style={{ maxWidth: '100%', marginBottom: '-5px' }} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        textAlign="left"
        marginTop={{ xs: '0', md: '0', lg: '44px' }}
        py={'50px'}
        order={{ xs: 1, md: 1, lg: 2 }}
      >
        <style>{`
        .downloadAppRichtext strong {
          color: ${theme.palette.primary.main};
          font-family: 'Tropiline';
          display: block;
          margin-top: 5px;
        }
        `}</style>
        <Typography
          color={theme.palette.secondary.main}
          fontWeight={500}
          textAlign={{ xs: 'center', md: 'center', lg: 'left' }}
          variant="h3"
          className="downloadAppRichtext"
          dangerouslySetInnerHTML={{ __html: content?.richText }}
        ></Typography>

        <Typography
          color={theme.palette.secondary.main}
          marginTop="12px"
          maxWidth="500px"
          sx={{ textAlign: 'left', [theme.breakpoints.down('lg')]: { textAlign: 'center' } }}
          variant="body1"
          mx={{ xs: 'auto', lg: 0 }}
        >
          {content?.textSubtitle ||
            `Discover pre-market and off-market properties you didn’t know were for sale. Listing Loop
            is where leading agents list their properties first. Get the app for VIP access before
            everyone else.`}
        </Typography>
        <Stack
          direction="row"
          height="40px"
          marginTop="28px"
          marginX={{ xs: 'auto', md: 'auto', lg: '0' }}
          spacing={1}
          width="fit-content"
        >
          <a href={content?.appStoreLink} style={linkStyle}>
            <img
              src={ImageAppStore}
              alt="Download from App store"
              style={{
                cursor: 'pointer',
                height: '40px',
                maxWidth: '100%',
                marginBottom: '-5px'
              }}
            />
          </a>
          <a href={content?.googlePlayLink} style={linkStyle}>
            <img
              src={ImageGooglePlayStore}
              alt="Download from Google Play store"
              style={{
                cursor: 'pointer',
                height: '40px',
                maxWidth: '100%',
                marginBottom: '-4px'
              }}
            />
          </a>
        </Stack>
      </Grid>
    </Grid>
  );
};
