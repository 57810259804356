import { styled } from '@mui/material';
import { Box, Theme } from '@mui/system';

export const WidthAdjustBox = styled(Box)(({ theme }: { theme?: Theme }) => ({
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));
