import { Card, CardContent, CardMedia, Chip, Divider, Typography, Skeleton } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { IconLocation } from '../assets/icons';
import { CAPTIONS_TEXT_COLOR, LIGHT_VIOLET_COLOR } from '../colors';
import { fetchImageURL } from '../services/image-service';
import { FlexBox } from './primitives/flex';

interface PropertyCardProps {
  address: string;
  imageLink: string;
  numberOfBathrooms: number;
  numberOfBedrooms: number;
  numberOfCarports: number;
  price: number;
}

export const PropertyCard = (props: PropertyCardProps) => {
  const { address, imageLink, numberOfBathrooms, numberOfBedrooms, numberOfCarports, price } =
    props;
  const [photoURL, setPhotoURL] = React.useState<string>('');

  /********************  STYLE *************************/
  const sxProps: { [className: string]: SxProps } = {
    card: {
      borderRadius: 0,
      boxShadow: 'none',
      maxWidth: '450px',
      width: '100%',
      minWidth: '240px'
    },
    cardMedia: {
      height: { xs: 180, md: 208 },
      borderRadius: '24px 24px 24px 0',
      width: '100%'
    },
    cardContent: {
      padding: 0
    },
    unlockText: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '12px'
    },
    divider: {
      color: LIGHT_VIOLET_COLOR,
      height: '14px'
    },
    price: {
      background: LIGHT_VIOLET_COLOR,
      color: 'secondary.main',
      fontSize: '14px'
    },
    features: { marginTop: '20px', alignItems: 'center', justifyContent: 'space-between' }
  };
  /******************** END STYLE *************************/

  React.useEffect(() => {
    fetchImageURL(imageLink).then((url) => setPhotoURL(url));
  }, []);

  return (
    <Card sx={sxProps.card}>
      {photoURL ? (
        <CardMedia alt="Property Image" component="img" image={photoURL} sx={sxProps.cardMedia} />
      ) : (
        <Skeleton sx={sxProps.cardMedia} variant="rectangular" />
      )}

      <CardContent sx={sxProps.cardContent}>
        <Typography color="secondary" fontWeight={500} mt="20px" variant="subtitle2">
          {address}
        </Typography>

        <Typography variant="body2" color={CAPTIONS_TEXT_COLOR} sx={sxProps.unlockText}>
          <IconLocation /> <span style={{ marginLeft: '8px' }}> Unlock to reveal address</span>
        </Typography>
        <FlexBox sx={sxProps.features}>
          <Typography alignItems="center" display="flex" variant="body1">
            {numberOfBedrooms} Bed&nbsp;
            <Divider orientation="vertical" sx={sxProps.divider} variant="middle" flexItem />
            &nbsp;
            {numberOfBathrooms} &nbsp;Bath&nbsp;
            <Divider orientation="vertical" sx={sxProps.divider} variant="middle" flexItem />
            &nbsp;{numberOfCarports} &nbsp;Car
          </Typography>
          <Chip label={'$' + price.toLocaleString('en-AU')} sx={sxProps.price} />
        </FlexBox>
      </CardContent>
    </Card>
  );
};
