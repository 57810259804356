import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'gatsby';
import { IconMap } from '../assets/icons/icon-map';
import { ListContainer } from './primitives/list-container';
import { ListItem } from './primitives/list-item';

export interface ListingLoopPointsProps {
  points: { text: string }[];
}

export const ListingLoopPointsSection = (props: ListingLoopPointsProps) => {
  const theme = useTheme();
  const { points } = props;
  return (
    <Grid
      container
      spacing={{ xs: 0, md: 2 }}
      alignItems={'center'}
      justifyContent="center"
      sx={{ flexGrow: 1, marginTop: '44px' }}
    >
      <Grid item xs={7} md={6}>
        <IconMap
          height="auto"
          style={{
            maxWidth: '433px'
          }}
          width="100%"
        />
      </Grid>
      <Grid item xs={12} md={5} mt={'50px'}>
        <Typography
          color="secondary"
          fontWeight={500}
          mb="30px"
          mx="12px"
          sx={{
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center'
            }
          }}
          variant="h3"
        >
          Australia’s leading place for off-market properties
        </Typography>
        <ListContainer>
          {points.map((point, i) => (
            <ListItem
              key={i}
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  fontFamily: 'Inter',
                  marginRight: '8px'
                }}
              >
                ✓
              </span>
              <span
                style={{
                  display: 'block'
                }}
              >
                {point.text}
              </span>
            </ListItem>
          ))}
        </ListContainer>
        <Typography
          color="primary.main"
          sx={{
            marginTop: '30px',
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center'
            }
          }}
        >
          <Link
            to={'#'}
            style={{
              color: 'inherit',
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 500,
              textDecoration: 'none',
              textTransform: 'none'
            }}
          >
            Curious to get in the loop?
            <span
              style={{
                fontFamily: 'Inter',
                marginLeft: '8px'
              }}
            >
              →
            </span>
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
