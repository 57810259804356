import { Box, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import { FlexBox } from './primitives/flex';
interface DiscoverPropertyCardProps {
  backgroundColor?: string;
  button?: {
    link: string;
    text: string;
  };
  description?: string;
  heading?: string;
  image?: JSX.Element;
}

export const DiscoverPropertyCard = (props: DiscoverPropertyCardProps) => {
  const { description, heading, button, backgroundColor, image } = props;
  return (
    <Card
      sx={{
        borderRadius: '26px 26px 26px 0',
        marginX: 'auto',
        maxWidth: '388px',
        height: 'auto',
        padding: 0
      }}
    >
      <Box
        style={{
          backgroundColor: backgroundColor === 'lightBlue' ? '#00a6dd' : '#061e3b',
          display: 'flex'
        }}
      >
        {image}
      </Box>
      <CardContent sx={{ flexGrow: 1, padding: '37px 30px', height: 'calc(100% - 200px)' }}>
        <FlexBox flexDirection="column" height="100%">
          <Typography color="secondary" fontWeight={500} variant="h6">
            {heading}
          </Typography>

          <Typography color="secondary.main" minHeight="96px" marginTop="20px" variant="body1">
            {description}
          </Typography>
          <Typography color="primary.light" sx={{ marginTop: 'auto', paddingTop: '20px' }}>
            <Link
              to={button.link}
              style={{
                color: 'inherit',
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 500,
                textDecoration: 'none',
                textTransform: 'none'
              }}
            >
              {button.text}
              <span
                style={{
                  fontFamily: 'Inter'
                }}
              >
                &nbsp;&nbsp;→
              </span>
            </Link>
          </Typography>
        </FlexBox>
      </CardContent>
    </Card>
  );
};
