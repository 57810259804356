import firebase from 'gatsby-plugin-firebase';

export function fetchImageURL(imageName: string, useCdn: boolean = false): Promise<string | null> {
  const path = 'images/' + imageName;
  return fetchStorageURL(path, useCdn);
}

async function fetchStorageURL(
  objectPath: string,
  isImageOrThumb: boolean
): Promise<string | null> {
  console.log(firebase.app());
  const storageRef = firebase.app().storage().ref().child(objectPath);
  let url: string | null = null;

  // .getDownloadURL() rejects the promise on 404 - catch the error
  try {
    if (isImageOrThumb == true) {
      url = 'https://images.listingloop.com.au/' + objectPath;
    } else {
      url = await storageRef.getDownloadURL();
    }
  } catch (err) {
    if (err.code && err.code === 'storage/object-not-found') {
      console.warn('Image not found', objectPath);
    } else {
      console.warn('Error while fetching storage URL', err);
    }
  }

  return url;
}
