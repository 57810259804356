import React from 'react';

export const IconLocation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.55" height="14.7" viewBox="0 0 11.55 14.7">
      <g id="Group_707" data-name="Group 707" transform="translate(-488.688 -1551.69)">
        <path
          id="Path_590"
          data-name="Path 590"
          d="M499.713,1557.465c0,2.955-2.053,4.654-3.592,6.3a18.254,18.254,0,0,0-1.658,2.1,18.061,18.061,0,0,0-1.654-2.095c-1.539-1.645-3.6-3.348-3.6-6.305a5.25,5.25,0,0,1,10.5,0Z"
          fill="none"
          stroke="#85979f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.05"
        />
        <circle
          id="Ellipse_31"
          data-name="Ellipse 31"
          cx="2.1"
          cy="2.1"
          r="2.1"
          transform="translate(492.363 1555.365)"
          fill="none"
          stroke="#85979f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.05"
        />
      </g>
    </svg>
  );
};
